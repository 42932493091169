import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/layout";

/* eslint-disable-next-line */
export interface ReferenceProps {}

export function Reference(props: ReferenceProps) {
  return (
    <React.Fragment>
      <Helmet title="Reference | Gostilna Spirić" defer={false} />
      <article className="page">
        <section className="container divide-y-2 content-card">
          <div className="">
            <h1 className="content-title">Reference</h1>

            <p className=" font-raleway">
              V svojih 37 letih delovanja smo stkali dobre poslovne odnose z
              mnogo dobavitelji, ki že vrsto let z nami sodelujejo in našemu
              podjetju zagotavljajo uspešno poslovanje.
            </p>
            <div className="flex items-center justify-center py-8">
              <StaticImage
                placeholder="tracedSVG"
                src="../images/dobavitelji/julius_logo.png"
                alt="Dobavitelj Julius Meinl"
                width={200}
              />
              <StaticImage
                placeholder="tracedSVG"
                src="../images/dobavitelji/kozel-logo.png"
                alt="Dobavitelj Kozel"
                width={200}
              />
              <StaticImage
                placeholder="tracedSVG"
                src="../images/dobavitelji/krampac-logo.png"
                alt="Dobavitelj Krampač"
                width={200}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <h2 className="mt-8 font-playfair">Ostali dobavitelji</h2>
            <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 lg:grid-cols-5 font-raleway">
              <span>Vigros d.o.o. </span>
              <span>Metlika d.o.o. </span>
              <span>Klub Prekmurskih študentov</span>
              <span>Radenska d.o.o. </span>
              <span>Coca-cola </span>
              <span>Deportivo d.o.o. </span>
              <span>Domen d.o.o. </span>
              <span>Ledo d.o.o. </span>
              <span>Zelena točka trans z.o.o. </span>
              <span>Pivovarna Laško Union d.o.o.</span>
              <span>Pekarna Vratarič d.o.o.</span>
              <span>Grah-com d.o.o.</span>
              <span>Mesnine Žerak d.o.o.</span>
              <span>Kajak klub Krog</span>
              <span>Skupina Blue Planet</span>
            </div>
            {/* <p>
                Vigros d.o.o., Metlika d.o.o., Klub Prekmurskih študentov,
                Radenska d.o.o., Coca-cola, Deportivo d.o.o., Domen d.o.o., Ledo
                d.o.o., Zelena točka trans z.o.o., Pivovarna Laško Union d.o.o.,
                Pekarna Vratarič d.o.o., Grah-com d.o.o., Mesnine Žerak d.o.o..
              </p> */}
          </div>
        </section>
      </article>
    </React.Fragment>
  );
}

export default Reference;
